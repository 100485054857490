export const DROPZONE_DEFAULT_OPTIONS = {
  url: "http://localhost",

  /**
   * Can be changed to `"put"` if necessary. You can also provide a function
   * that will be called with `files` and must return the method (since `v3.12.0`).
   */
  method: "post",

  /**
   * Will be set on the XHRequest.
   */
  withCredentials: false,

  /**
   * The timeout for the XHR requests in milliseconds (since `v4.4.0`).
   * If set to null or 0, no timeout is going to be set.
   */
  timeout: null,

  /**
   * How many file uploads to process in parallel (See the
   * Enqueuing file uploads documentation section for more info)
   */
  parallelUploads: 2,

  /**
   * Whether to send multiple files in one request. If
   * this it set to true, then the fallback file input element will
   * have the `multiple` attribute as well. This option will
   * also trigger additional events (like `processingmultiple`). See the events
   * documentation section for more information.
   */
  uploadMultiple: false,

  /**
   * Whether you want files to be uploaded in chunks to your server. This can't be
   * used in combination with `uploadMultiple`.
   *
   * See [chunksUploaded](#config-chunksUploaded) for the callback to finalise an upload.
   */
  chunking: false,

  /**
   * If `chunking` is enabled, this defines whether **every** file should be chunked,
   * even if the file size is below chunkSize. This means, that the additional chunk
   * form data will be submitted and the `chunksUploaded` callback will be invoked.
   */
  forceChunking: false,

  /**
   * If `chunking` is `true`, then this defines the chunk size in bytes.
   */
  chunkSize: 2 * 1024 * 1024,

  /**
   * If `true`, the individual chunks of a file are being uploaded simultaneously.
   */
  parallelChunkUploads: false,

  /**
   * Whether a chunk should be retried if it fails.
   */
  retryChunks: false,

  /**
   * If `retryChunks` is true, how many times should it be retried.
   */
  retryChunksLimit: 3,

  /**
   * The maximum filesize (in MiB) that is allowed to be uploaded.
   */
  maxFilesize: 256,

  /**
   * The name of the file param that gets transferred.
   * **NOTE**: If you have the option  `uploadMultiple` set to `true`, then
   * Dropzone will append `[]` to the name.
   */
  paramName: "file",

  /**
   * Whether thumbnails for images should be generated
   */
  createImageThumbnails: true,

  /**
   * In MB. When the filename exceeds this limit, the thumbnail will not be generated.
   */
  maxThumbnailFilesize: 10,

  /**
   * If `null`, the ratio of the image will be used to calculate it.
   */
  thumbnailWidth: 150,

  /**
   * The same as `thumbnailWidth`. If both are null, images will not be resized.
   */
  thumbnailHeight: 120,

  /**
   * How the images should be scaled down in case both, `thumbnailWidth` and `thumbnailHeight` are provided.
   * Can be either `contain` or `crop`.
   */
  thumbnailMethod: "crop",

  /**
   * If set, images will be resized to these dimensions before being **uploaded**.
   * If only one, `resizeWidth` **or** `resizeHeight` is provided, the original aspect
   * ratio of the file will be preserved.
   *
   * The `options.transformFile` function uses these options, so if the `transformFile` function
   * is overridden, these options don't do anything.
   */
  resizeWidth: null,

  /**
   * See `resizeWidth`.
   */
  resizeHeight: null,

  /**
   * The mime type of the resized image (before it gets uploaded to the server).
   * If `null` the original mime type will be used. To force jpeg, for example, use `image/jpeg`.
   * See `resizeWidth` for more information.
   */
  resizeMimeType: null,

  /**
   * The quality of the resized images. See `resizeWidth`.
   */
  resizeQuality: 0.8,

  /**
   * How the images should be scaled down in case both, `resizeWidth` and `resizeHeight` are provided.
   * Can be either `contain` or `crop`.
   */
  resizeMethod: "contain",

  /**
   * The base that is used to calculate the **displayed** filesize. You can
   * change this to 1024 if you would rather display kibibytes, mebibytes,
   * etc... 1024 is technically incorrect, because `1024 bytes` are `1 kibibyte`
   * not `1 kilobyte`. You can change this to `1024` if you don't care about
   * validity.
   */
  filesizeBase: 1000,

  /**
   * If not `null` defines how many files this Dropzone handles. If it exceeds,
   * the event `maxfilesexceeded` will be called. The dropzone element gets the
   * class `dz-max-files-reached` accordingly so you can provide visual
   * feedback.
   */
  maxFiles: null,

  /**
   * An optional object to send additional headers to the server. Eg:
   * `{ "My-Awesome-Header": "header value" }`
   */
  headers: null,

  /**
   * Should the default headers be set or not?
   * Accept: application/json <- for requesting json response
   * Cache-Control: no-cache <- Request shouldnt be cached
   * X-Requested-With: XMLHttpRequest <- We sent the request via XMLHttpRequest
   */
  defaultHeaders: true,

  /**
   * If `true`, the dropzone element itself will be clickable, if `false`
   * nothing will be clickable.
   *
   * You can also pass an HTML element, a CSS selector (for multiple elements)
   * or an array of those. In that case, all of those elements will trigger an
   * upload when clicked.
   */
  clickable: true,

  /**
   * Whether hidden files in directories should be ignored.
   */
  ignoreHiddenFiles: true,

  /**
   * The default implementation of `accept` checks the file's mime type or
   * extension against this list. This is a comma separated list of mime
   * types or file extensions.
   *
   * Eg.: `image/*,application/pdf,.psd`
   *
   * If the Dropzone is `clickable` this option will also be used as
   * [`accept`](https://developer.mozilla.org/en-US/docs/HTML/Element/input#attr-accept)
   * parameter on the hidden file input as well.
   */
  acceptedFiles: null,

  /**
   * **Deprecated!**
   * Use acceptedFiles instead.
   */
  acceptedMimeTypes: null,

  /**
   * If false, files will be added to the queue but the queue will not be
   * processed automatically.
   * This can be useful if you need some additional user input before sending
   * files (or if you want want all files sent at once).
   * If you're ready to send the file simply call `myDropzone.processQueue()`.
   *
   * See the [enqueuing file uploads](#enqueuing-file-uploads) documentation
   * section for more information.
   */
  autoProcessQueue: false,

  /**
   * If false, files added to the dropzone will not be queued by default.
   * You'll have to call `enqueueFile(file)` manually.
   */
  autoQueue: true,

  /**
   * If `true`, this will add a link to every file preview to remove or cancel (if
   * already uploading) the file. The `dictCancelUpload`, `dictCancelUploadConfirmation`
   * and `dictRemoveFile` options are used for the wording.
   */
  addRemoveLinks: false,

  /**
   * Defines where to display the file previews – if `null` the
   * Dropzone element itself is used. Can be a plain `HTMLElement` or a CSS
   * selector. The element should have the `dropzone-previews` class so
   * the previews are displayed properly.
   */
  previewsContainer: false,

  /**
   * Set this to `true` if you don't want previews to be shown.
   */
  disablePreviews: true,

  /**
   * This is the element the hidden input field (which is used when clicking on the
   * dropzone to trigger file selection) will be appended to. This might
   * be important in case you use frameworks to switch the content of your page.
   *
   * Can be a selector string, or an element directly.
   */
  hiddenInputContainer: "body",

  /**
   * If null, no capture type will be specified
   * If camera, mobile devices will skip the file selection and choose camera
   * If microphone, mobile devices will skip the file selection and choose the microphone
   * If camcorder, mobile devices will skip the file selection and choose the camera in video mode
   * On apple devices multiple must be set to false.  AcceptedFiles may need to
   * be set to an appropriate mime type (e.g. "image/*", "audio/*", or "video/*").
   */
  capture: null,

  /**
   * **Deprecated**. Use `renameFile` instead.
   */
  renameFilename: null,

  /**
   * A function that is invoked before the file is uploaded to the server and renames the file.
   * This function gets the `File` as argument and can use the `file.name`. The actual name of the
   * file that gets used during the upload can be accessed through `file.upload.filename`.
   */
  renameFile: null,

  /**
   * If `true` the fallback will be forced. This is very useful to test your server
   * implementations first and make sure that everything works as
   * expected without dropzone if you experience problems, and to test
   * how your fallbacks will look.
   */
  forceFallback: false,

  /**
   * The text used before any files are dropped.
   */
  dictDefaultMessage: "Перетащите файлы для загрузки",

  /**
   * The text that replaces the default message text it the browser is not supported.
   */
  dictFallbackMessage: "Ваш браузер не поддерживаеи drag'n'drop операцию для загрузки файлов.",

  /**
   * The text that will be added before the fallback form.
   * If you provide a  fallback element yourself, or if this option is `null` this will
   * be ignored.
   */
  dictFallbackText: "Пожалуйста, используйте резервную форму ниже, чтобы загрузить файлы",

  /**
   * If the filesize is too big.
   * `{{filesize}}` and `{{maxFilesize}}` will be replaced with the respective configuration values.
   */
  dictFileTooBig:
    "Размер файла ({{filesize}}MiB) и превышает допустимый размер: {{maxFilesize}}MiB.",

  /**
   * If the file doesn't match the file type.
   */
  dictInvalidFileType: "Файлы этого типа недоступны для загрузки.",

  /**
   * If the server response was invalid.
   * `{{statusCode}}` will be replaced with the servers status code.
   */
  dictResponseError: "Сервер отвеитл кодом {{statusCode}}.",

  /**
   * If `addRemoveLinks` is true, the text to be used for the cancel upload link.
   */
  dictCancelUpload: "Отменить загрузку",

  /**
   * The text that is displayed if an upload was manually canceled
   */
  dictUploadCanceled: "Загрузка отменена",

  /**
   * If `addRemoveLinks` is true, the text to be used for confirmation when cancelling upload.
   */
  dictCancelUploadConfirmation: "Вы хотите отменить загрузку?",

  /**
   * If `addRemoveLinks` is true, the text to be used to remove a file.
   */
  dictRemoveFile: "Удалить файл",

  /**
   * If this is not null, then the user will be prompted before removing a file.
   */
  dictRemoveFileConfirmation: null,

  /**
   * Displayed if `maxFiles` is st and exceeded.
   * The string `{{maxFiles}}` will be replaced by the configuration value.
   */
  dictMaxFilesExceeded: "Превышено количество загружаемых файлов",

  /**
   * Allows you to translate the different units. Starting with `tb` for terabytes and going down to
   * `b` for bytes.
   */
  dictFileSizeUnits: {
    tb: "Тб",
    gb: "Гб",
    mb: "Мб",
    kb: "Кб",
    b: "б",
  },
};
