import { AttachmentItemData } from "@/shared/types/components/Base/AttachmentItem";
import { AttachmentInput, ContentType } from "@/shared/types/schema.types";

function formatContentType(mimeType: string): ContentType {
  if (mimeType.startsWith("image/")) return ContentType.Img;
  else if (mimeType.startsWith("text/")) return ContentType.Txt;
  return ContentType.Bin;
}

export function encodeAttachmentInput(attachmentItemData: AttachmentItemData[]): AttachmentInput[] {
  return attachmentItemData.map((el) => {
    return {
      ..._.pick(el, ["contentLength", "name", "url"]),
      contentType: formatContentType(el.contentType),
      urlPreview: el.urlPreview || "",
    };
  });
}
