import { apClient } from "@/app/core/gQL/apolloClient";
import { apolloClients } from "@/shared/enums/api";
import {
  Attachment,
  UploadSingleFileDocument,
  UploadSingleFileMutation,
  UploadSingleFileMutationVariables,
} from "@/shared/types/schema.upload.types";

export async function uploadSingle(params: UploadSingleFileMutationVariables): Promise<Attachment> {
  const { uploadSingle } = await apClient.set<
    UploadSingleFileMutation,
    UploadSingleFileMutationVariables
  >(UploadSingleFileDocument, params, undefined, apolloClients.UPLOAD);
  return uploadSingle as Attachment;
}
