import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UnixTimestamp: any;
  /** A file part in a multipart request */
  Upload: any;
};

export type Attachment = {
  __typename?: 'Attachment';
  contentLength: Scalars['Int'];
  contentType: Scalars['String'];
  createdAt?: Maybe<Scalars['UnixTimestamp']>;
  filename: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  originalDatetime?: Maybe<Scalars['UnixTimestamp']>;
  smallImage?: Maybe<Preview>;
  type?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  uploadSingle: Attachment;
};


export type MutationUploadSingleArgs = {
  upload: Scalars['Upload'];
};

export type Preview = {
  __typename?: 'Preview';
  height: Scalars['Int'];
  size: Scalars['Int'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  appInfo: Scalars['String'];
};

export type UploadSingleFileMutationVariables = Exact<{
  upload: Scalars['Upload'];
}>;


export type UploadSingleFileMutation = { __typename?: 'Mutation', uploadSingle: { __typename?: 'Attachment', createdAt?: any | null | undefined, url: string, contentLength: number, contentType: string, filename: string, metadata?: string | null | undefined, smallImage?: { __typename?: 'Preview', url: string } | null | undefined } };


export const UploadSingleFileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"uploadSingleFile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"upload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadSingle"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"upload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"upload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"contentLength"}},{"kind":"Field","name":{"kind":"Name","value":"contentType"}},{"kind":"Field","name":{"kind":"Name","value":"filename"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}},{"kind":"Field","name":{"kind":"Name","value":"smallImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]} as unknown as DocumentNode<UploadSingleFileMutation, UploadSingleFileMutationVariables>;