





































import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import { computed, defineComponent, PropType } from "@vue/composition-api";
import vue2Dropzone from 'vue2-dropzone';

import AttachmentItem from "@/app/components/Base/AttachmentItem.vue";
import { DROPZONE_DEFAULT_OPTIONS } from "@/shared/constants/components/Base/Dropzone/DropzoneOptions";
import { useNotification } from "@/shared/hooks/notification";
import { uploadSingle } from "@/shared/requests/upload";
import { AttachmentItemData } from "@/shared/types/components/Base/AttachmentItem";
import { DropzoneOptions } from "@/shared/types/components/Base/Dropzone";
import { formatFileSize } from "@/shared/utils";


export default defineComponent({
  name: "Dropzone",
  components: {
    vueDropzone: vue2Dropzone,
    AttachmentItem, 
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: _.uniqueId('dropzone-'),
    },
    value: {
      type: Array as PropType<AttachmentItemData[]>,
      default: () => [],
    },
    options: {
      type: Object as PropType<Partial<DropzoneOptions>>,
      default: () => DROPZONE_DEFAULT_OPTIONS,
    },
  },
  setup(props, { emit }) {
    const mergedOptions = computed(() => _.merge(_.cloneDeep(DROPZONE_DEFAULT_OPTIONS), props.options));
    const { errNotification } = useNotification();
    
    async function uploadFiles(file: File) {
      try {
        const uploadedFile = await uploadSingle({ upload: file });
        emit('input', [...props.value, ...[{
          ..._.omit(uploadedFile, 'createdAt', 'metadata', 'smallImage', 'filename', '__typename'),
          name: uploadedFile.filename,
          availableActions: { remove: true },
          urlPreview: uploadedFile.smallImage?.url,
        }]]);
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }

    function removeFile(item: AttachmentItemData) {
      emit('input', props.value.filter(el => el.url !== item.url));
    }

    return {
      mergedOptions,
      uploadFiles,
      removeFile,
      formatFileSize,
    };
  },
});
