




















































import { computed, defineComponent, PropType } from "@vue/composition-api";

import { AttachmentItemMode } from "@/shared/enums/components/Base/AttachmentItem.enums";
import { AttachmentItemData } from "@/shared/types/components/Base/AttachmentItem";
import { downloadFileByUrl, formatFileSize } from "@/shared/utils";

export default defineComponent({
  name: "AttachmentItem",
  props: {
    item: {
      type: Object as PropType<AttachmentItemData>,
      default: undefined,
    },
    mode: {
      type: String as PropType<AttachmentItemMode>,
      default: AttachmentItemMode.EDIT,
    },
  },
  setup(props) {

    const availableActionsFilteredByMode = computed(() => {
      if (!props.item) return [];
      const actions = _.keys(props.item.availableActions);

      if (props.mode === AttachmentItemMode.VIEW) return actions.filter(action => action !== 'remove');
      else if (props.mode === AttachmentItemMode.EDIT) return actions.filter(action => action !== 'download');
      return [];
    });

    const canRemove = computed(() => props.item.availableActions?.remove && availableActionsFilteredByMode.value.includes('remove'));
    const canDownload = computed(() => props.item.availableActions?.download && availableActionsFilteredByMode.value.includes('download'));

    return {
      canRemove,
      formatFileSize,
      downloadFileByUrl,
      canDownload,
      availableActionsFilteredByMode,
    };
  },
});
